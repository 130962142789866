// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Division from '@bodhi-project/components/lib/Division'

import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Copy from '../components/Copy'
import StandardPage from '../components/StandardPage'
import Video from '../components/Video'

import Academics from '../components/commons/Academics'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Academics',
  nakedPageSlug: 'academics',
  pageAbstract:
    'The Cambridge Innovative School Curriculum has been designed keeping in mind the pedagogic needs of the regions our schools are based in and connecting it to international standards. Our curriculum has been developed by carrying out ongoing small-scale research projects, pilot projects and action research..',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    iconOne: file(relativePath: { eq: "academics/icons/one.jpg" }) {
      ...defaultImage
    }
    iconTwo: file(relativePath: { eq: "academics/icons/two.jpg" }) {
      ...defaultImage
    }
    iconThree: file(relativePath: { eq: "academics/icons/three.jpg" }) {
      ...defaultImage
    }
    iconFour: file(relativePath: { eq: "academics/icons/four.jpg" }) {
      ...defaultImage
    }
    middle1: file(relativePath: { eq: "academics/middle1.jpg" }) {
      ...defaultImage
    }
    middle2: file(relativePath: { eq: "academics/middle2.jpg" }) {
      ...defaultImage
    }
    middle3: file(relativePath: { eq: "academics/middle3.jpg" }) {
      ...defaultImage
    }
    middle4: file(relativePath: { eq: "academics/middle4.jpg" }) {
      ...defaultImage
    }
    primary1: file(relativePath: { eq: "academics/primary1.jpg" }) {
      ...defaultImage
    }
    primary2: file(relativePath: { eq: "academics/primary2.jpg" }) {
      ...defaultImage
    }
    primary3: file(relativePath: { eq: "academics/primary3.jpg" }) {
      ...defaultImage
    }
    primary4: file(relativePath: { eq: "academics/primary4.jpg" }) {
      ...defaultImage
    }
    secondary1: file(relativePath: { eq: "academics/secondary1.jpg" }) {
      ...defaultImage
    }
    secondary2: file(relativePath: { eq: "academics/secondary2.jpg" }) {
      ...defaultImage
    }
    secondary3: file(relativePath: { eq: "academics/secondary3.jpg" }) {
      ...defaultImage
    }
    secondary4: file(relativePath: { eq: "academics/secondary4.jpg" }) {
      ...defaultImage
    }
    senior1: file(relativePath: { eq: "academics/senior1.jpg" }) {
      ...defaultImage
    }
    senior2: file(relativePath: { eq: "academics/senior2.jpg" }) {
      ...defaultImage
    }
    senior3: file(relativePath: { eq: "academics/senior3.jpg" }) {
      ...defaultImage
    }
    senior4: file(relativePath: { eq: "academics/senior4.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Education at Cambridge International School</h1>
    <Division bigGolden>
      <div>
        <Copy>
          <p>
            The Cambridge Innovative School Curriculum has been designed
            keeping in mind the pedagogic needs of the regions our schools are
            based in and connecting it to international standards. Our
            curriculum has been developed by carrying out ongoing small-scale
            research projects, pilot projects and action research.
          </p>
          <p style={{ marginBottom: 0 }}>
            We have tried to create a flexible curricular model that can be
            personalized to the needs of varied learners. Our curriculum not
            only covers academic areas but also covers the social, cultural
            issues and challenges that the 21st century poses for young people.
            Our students are free to choose from a range of curriculums.
          </p>
        </Copy>
      </div>
      <div>
        <Video
          url="https://www.youtube.com/watch?v=VkKAzNWUVso"
          style={{ marginBottom: 0 }}
        />
      </div>
    </Division>
    <hr style={{ marginTop: 30, marginBottom: 30, borderColor: '#004195' }} />
    <Academics data={props.data} />
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
