// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import NewImages from './NewImages'
import NewInfobox from './NewInfoBox'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// show = [
//   'artAndCrafts',
//   'library',
//   'scienceLab',
//   'musicStudies',
//   'socialScienceLab',
//   'sports',
//   'playroom',
//   'swimmingPool',
//   'security',
//   'specialNeeds',
//   'ictLab',
//   'classrooms',
// ],

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Academics */
const Academics = props => {
  const { data } = props
  const display = [
    {
      index: 0,
      iconKey: 'iconOne',
      text: 'Primary School',
      info: () => (
        <div>
          <h2 className="mask-h4">Primary School Education</h2>
          <p>
            <i>
              <strong>Age Group — 6-11 Years</strong>
            </i>
          </p>
          <p>
            Cambridge Innovative School offers a curriculum which is balanced and broadly
            based for the young girls to start the journey of education without
            burden or fear. Nurturing environment of opportunities helps in
            academic and co-scholastic develop curiosity unlimited, which
            ensures that students have fun, play and aim to achieve high.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="primary"
              columns={{ min: 2, max: 2 }}
            />
          </div>
          <p>
            Primary education consists of an year cycle: first to fifth classes.
            The primary curriculum aims to provide a broad learning experience
            and encourages a rich variety of approaches to teaching and learning
            that cater to the varied needs of individual children. The primary
            curriculum is designed to nurture the child in all dimensions of his
            or her life—spiritual, moral, cognitive, emotional, imaginative,
            aesthetic, social and physical.
          </p>
          <p>
            <strong>Curriculum</strong>
          </p>
          <p>
            The Primary School Curriculum outlines the contents of children’s
            learning—the what and how of children’s learning—for children’s
            first eight years in school, from junior infants to sixth class. The
            curriculum aims to:
          </p>
          <ul className="mask-p">
            <li>Develop each child’s potential to the full</li>
            <li>Encourage love of learning</li>
            <li>
              Help children develop skills which will be useful throughout life
            </li>
          </ul>
          <p>The curriculum has range of Subjects:</p>
          <ul className="mask-p">
            <li>Language: English, Punjabi, Hindi and French</li>
            <li>Mathematics</li>
            <li>Social Science: History, Geography and Civics</li>
            <li>Arts education: Visual Arts, Music and Drama</li>
            <li>Physical education</li>
            <li>Value Education with Real Projects </li>
            <li>Spiritual Education </li>
            <li>Activities and Clubs are core of learning-teaching. </li>
          </ul>
          <p>
            <strong>Assessment</strong>
          </p>
          <p>
            Assessment in Primary School is about building a picture over time
            of a child’s learning progress across the curriculum. The teacher
            uses different ways to gather evidence about how and what the child
            learns on an ongoing basis. This information is used to celebrate
            the child’s current learning, and to help make decisions about next
            steps for future learning.
          </p>
        </div>
      ),
    },
    {
      index: 1,
      iconKey: 'iconTwo',
      text: 'Middle School',
      info: () => (
        <div>
          <h2 className="mask-h4">Middle School Programme CBSE</h2>
          <p>
            <i>
              <strong>The Middle School</strong>
            </i>
          </p>
          <p>
            During the middle school, the student’s intellectual, aesthetic,
            physical and cultural growth is intensified and extended. It is at
            this stage school parents play pivotal role in shaping Girls as
            confident and resilient individuals.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="middle"
              columns={{ min: 2, max: 2 }}
            />
          </div>
          <p>
            Interdisciplinary methods of teaching help to explore beyond limited
            learning, peer interactions and presentations help each girl to be
            socially aware and active.
          </p>
          <p>
            Setting a high standard facilitates the smooth transition from
            childhood to adolescence. Students are given confidence that they can
            succeed. They are made active partners by building respectful
            teacher-learner relationships that take learners’ viewpoint and
            experiences into account. Pastoral care is given equal value for
            creating the feeling of belonging for students to be independent
            thinkers.
          </p>
          <p>
            Using assessment for learning to help learners assess their work,
            reflect on how they learn, and become active members of the process.
          </p>
          <p>
            Clubs and Activities are carefully planned to balance the physical
            and mental growth.
          </p>
          <p>Subjects at the middle level:</p>
          <ul className="mask-p">
            <li>
              Scholastic
              <ul style={{ fontSize: 'unset' }}>
                <li style={{ fontSize: 'unset' }}>
                  English, Hindi/ Punjabi, Third language, Science, Mathematics,
                  Social Studies, Computer Education.
                </li>
              </ul>
            </li>
            <li>
              Non-Scholastic
              <ul style={{ fontSize: 'unset' }}>
                <li style={{ fontSize: 'unset' }}>
                  Art and Craft, Music, Dance, Sports, Swimming, Home Science,
                  Robotics, Drama, Theatre and Arts.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ),
    },
    {
      index: 2,
      iconKey: 'iconThree',
      text: 'Secondary',
      info: () => (
        <div>
          <h2 className="mask-h4">The Secondary School</h2>
          <p>
            <i>
              <strong>Stepping Into Life</strong>
            </i>
          </p>
          <p>
            Knowledge and skills gained during the Middle School years equip
            children with a firm foundation in the core subjects and develop strong
            learning skills to pursue a variety of educational streams in
            future.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="secondary"
              columns={{ min: 2, max: 2 }}
            />
          </div>
          <p>
            Opportunities are provided to research, solve problems and make
            considered decisions so that over a period our students achieve
            self-reliance in learning and develop confidence and an
            understanding of the learning process.
          </p>
          <p>
            Leadership is nurtured through various opportunities to lead,
            organise events for academic and social areas. At this stage, we
            believe, each girl is to be nurtured to be brave, intelligent and
            resilient. We aim ours students to be women of vision and make the
            world better.
          </p>
          <p>Subject at the Secondary Level:</p>
          <ul className="mask-p">
            <li>
              Scholastic
              <ul style={{ fontSize: 'unset' }}>
                <li style={{ fontSize: 'unset' }}>
                  English, Hindi/Punjabi, Mathematics, Science, Social Studies,
                  Computer Education, and Optional subjects from the vocational
                  and mainstream studies.
                </li>
              </ul>
            </li>
            <li>
              Non-Scholastic
              <ul style={{ fontSize: 'unset' }}>
                <li style={{ fontSize: 'unset' }}>
                  Art and Craft, Music, Dance, Sports, Swimming, Home Science,
                  Robotics, Drama, Theatre, Language Arts.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ),
    },
    {
      index: 3,
      iconKey: 'iconFour',
      text: 'Senior Secondary',
      info: () => (
        <div>
          <h2 className="mask-h4">The Senior Secondary School</h2>
          <p>
            <i>
              <strong>Gateway to a creative life</strong>
            </i>
          </p>
          <p>
            This is the plus two stage in the 10+2 pattern of education leading
            to the All India Senior Secondary Education examination conducted by
            Central Board of Secondary Education, Delhi.
          </p>
          <div className="mask-p">
            <NewImages
              data={data}
              lookup="senior"
              columns={{ min: 2, max: 2 }}
            />
          </div>
          <p>
            Study in this phase becomes more specialised and students become
            focussed on future career targets. Specialist teaching is emphasised
            with the options of choices from Humanities, Commerce, and Sciences.
            But these do not limit our Girls from dreaming, they are motivated
            to take up their passion.
          </p>
          <p>
            Our school is well resourced with excellent caring staff,
            facilities, equipment and technology for their future to be as per
            their dreams.
          </p>
          <p>
            Our commitment for future prepared students is not only an objective
            but a commitment to not let these valuable years be lost. Along with
            core subjects of sciences, commerce and humanities, we provide
            Liberal Arts possibilities with subjects like Psychology,
            Cosmetology, Food Technology, Fashion Technology, Information
            Technology, Legal studies.
          </p>
          <p>
            Special care is taken to offer subjects that are contemporary and
            relevant for the future career. Diverse subject combinations are
            offered to make this crucial phase of schooling joyous and stress
            free.
          </p>
          <p>
            From here our students are ready to take charge of themselves and
            others.
          </p>
        </div>
      ),
    },
  ]

  return (
    <NewInfobox
      data={data}
      display={display}
      defaultIndex={0}
      divisionProps={{ custom: [4, 20] }}
      columns={1}
      activeStyle={{ border: '4px solid #FFC900', borderRadius: 6 }}
    />
  )
}

Academics.propTypes = {}

/* eslint-disable react/forbid-prop-types */
Academics.propTypes = {
  show: PropTypes.array,
  exclude: PropTypes.array,
  columns: PropTypes.array,
  divisionProps: PropTypes.array,
  activeStyle: PropTypes.array,
  inactiveStyle: PropTypes.array,
  schoolName: PropTypes.string,
  shortSchoolName: PropTypes.string,
  artPhotos: PropTypes.array,
  libraryPhotos: PropTypes.array,
  labs: PropTypes.object,
  musicDancePhotos: PropTypes.array,
  sportsPhotos: PropTypes.array,
  swimmingPoolPhotos: PropTypes.array,
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Academics
